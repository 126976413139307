import React, {useState} from 'react';
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { Hero, Breadcrumb, Modal, Button, VideoComponent } from "components/ui";
import { Overlay } from "components/global";
import { useStrapiVideosWebinarsData  } from "hooks";
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import { FlagCardContainer } from "components/containers/Cards";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"


//default layout for series  pages
const SerieEntry= ({ location, data, pageContext: { topicSlug } }) => {
  const [selectedVideoTitle, setSelectedVideoTitle] = useState("");
  const [selectedVideoId, setSelectedVideoId] = useState("");
  const [modalDisplay, setModalDisplay] = useState(false);
  const { allStrapiVideo } = useStrapiVideosWebinarsData();
    const serie = data?.strapiSerie
    const modules = [...serie?.resources, ...serie?.videos_webinars_series]

    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }
    const seo = {
        metaTitle: serie?.title,
        metaDescription: serie?.description,
        shareImage: getSrc(serie?.thumbnail?.localFile),
        metaKeywords: serie?.keywords
    }
    const updateModalDisplay=e=>{
      e.preventDefault();
      setModalDisplay(!modalDisplay);
    }
    const selectedVideo=e=>{
      e.preventDefault();
      setModalDisplay(!modalDisplay);
      let selectedVideo = allStrapiVideo?.nodes.filter(resource=>{
          return resource?.title === e.target.title;
      })
      setSelectedVideoTitle(selectedVideo[0]?.title);
      selectedVideo[0]?.video_url.includes("=") ? setSelectedVideoId(selectedVideo[0]?.video_url.substring(selectedVideo[0]?.video_url?.indexOf('=')+1)) : setSelectedVideoId(selectedVideo[0]?.video_url.substring(selectedVideo[0]?.video_url.indexOf('.')+4));
  }

  return (
    <Layout location={location}>
        <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
        <div>
          <Hero
            img={getSrc(serie?.hero_image?.background_image?.localFile)}
            color="#f5f5f5"
            >
              <Breadcrumb pathname={thisLocationPathname} customCurrentPage={serie?.title} />
              {
                serie?.slug === "expert-video-series" ?
                <>
                  <h1 className="usa-hero__heading" aria-label={`Hero: ${serie?.hero_image?.headline.split(":")[0].trim()}`} name="main-content" id="main-content">{serie?.hero_image?.headline.split(":")[0].trim()}:</h1>
                  <h2>{serie?.hero_image?.headline.split(":")[1].trim()}</h2>
                </>
              :
                <h1 className="usa-hero__heading" aria-label={`Hero: ${serie?.hero_image?.headline}`} name="main-content" id="main-content">{serie?.hero_image?.headline}</h1>
              }
              {serie?.hero_image?.description &&
                <div
                  dangerouslySetInnerHTML={{
                  __html: serie?.hero_image?.description?.data?.childMarkdownRemark?.html,
                  }}
                />
              }
            </Hero>
            <div className="padding-top-5">
              <div className="grid-container">
                <div className="grid-row grid-gap">
                  {serie?.body &&
                  <div className="desktop:grid-col-7 tablet:grid-col-12"
                    dangerouslySetInnerHTML={{
                    __html: serie?.body?.data?.childMarkdownRemark?.html,
                    }}
                  />
                }
                </div>
              </div>
            </div>
            <div>
                <FlagCardContainer
                    key="1"
                    resources={modules}
                    buttonOnClick={selectedVideo}
                    containerStyle="white-bg"
                    headerColor="text-base-lightest"
                    keyName="series-module"
                    cardColor="bg-white"
                    grid="desktop:grid-col-12 min-height-flag-card-container card-detail card-mobile"
                    border="border-gray-10"
                    topicPage={false}
                    containerPadding={serie?.notes ? "padding-top-5" : "padding-y-5"}
                    seriesLocation={thisLocationPathname}
                />
            </div>
          {serie?.notes &&
            <div className="padding-bottom-5">
              <div className="grid-container">
                <div className="grid-row grid-gap">
                  {serie?.notes &&
                  <div className="desktop:grid-col-7 tablet:grid-col-12"
                    dangerouslySetInnerHTML={{
                    __html: serie?.notes?.data?.childMarkdownRemark?.html,
                    }}
                  />
                } 
                </div>
              </div>
            </div>
          }
        </div>
        <Overlay isVisible={modalDisplay} />
        <Modal
          modalId={`series-page-video-webinar-modal`}
          modalType="usa-modal--lg radius-lg"
          modalDisplay={modalDisplay}
          >
          <Modal.ModalButton closeModal={updateModalDisplay}/>
            <Modal.ModalBody modalBodyClass="text-center">
              <div className="padding-top-6">
                <VideoComponent
                  videoSrcId={selectedVideoId}
                  videoTitle={selectedVideoTitle}
                />
              </div>
            </Modal.ModalBody>
            <Modal.ModalFooter modalFooterClass="text-center">
              <Button color="primary-button" size="width-card-lg margin-bottom-3" closeModal={true} onClick={updateModalDisplay}>
                Close
              </Button>
            </Modal.ModalFooter>
        </Modal>
    </Layout>
    );

};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiSerie(slug: {eq: $slug}) {
        id
        slug
        title
        description
        keywords
        body {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        notes {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        hero_image {
          headline
          description {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          background_image {
            alternativeText
            localFile {
              url
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        thumbnail {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
        }
        resources {
            slug
            title
            card_title
            category {
              name
            }
            author
            external_resource
            external_url_resource
            external_file_size
            open_external_in_new_window
            resource_date(formatString: "YYYY")
            body {
              data {
                childMarkdownRemark {
                  html
                  rawMarkdownBody
                  excerpt(format: PLAIN, pruneLength: 85)
                }
              }
            }
            thumbnail {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 205 height: 265)
                }
              }
            }
            pdf {
              name
              localFile {
                prettySize
              }
            }
        }
        videos_webinars_series {
            slug
            title
            card_title
            external_resource
            category {
              name
            }
            author
            date(formatString: "MMMM DD, YYYY")
            body {
              data {
                childMarkdownRemark {
                  rawMarkdownBody
                  excerpt(format: PLAIN, pruneLength: 136)
                }
              }
            }
            thumbnail {
              alternativeText
              localFile {
                childImageSharp {
                    gatsbyImageData(aspectRatio: 1.77, width: 236)
                  }
              }
            }
            video_url
        }
    }
  }
`

export default SerieEntry;
